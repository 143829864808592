/* ImageWithText.css */

.container {
    display: flex;
    align-items: center;
  }
  
  .image-container {
    flex: 1;
  }
  
  .image {
    width: 100%;
    max-width: 400px; /* Adjust as needed */
    height: auto;
  }
  
  .text-container {
    flex: 1;
    padding: 0 20px; /* Adjust as needed */
  }
  
  .text {
    font-size: 16px;
    line-height: 1.6;
  }
  