.photo-gallery {
    display: flex;
    justify-content: center;
    gap: 40px; /* Increased gap */
    margin-top: 50px; /* Increased top margin */
  }
  
  .photo-container {
    position: relative; /* Added for overlay positioning */
    width: 300px; /* Increased size */
    height: 500px; /* Increased height */
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s; /* Add transition for hover effect */
    margin-bottom: 2em; /* Added relative margin on the bottom */
  }
  
  .photo-container:hover {
    transform: scale(1.05) rotate(3deg); /* Scale and rotate on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
  }
  
  .photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s;
  }
  
  .photo.animate {
    transform: scale(1.1);
  }
  
  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    text-align: center;
    padding: 10px;
    opacity: 0;
    transition: opacity 0.5s;
    border-radius: 0 0 20px 20px;
  }
  
  .photo-container:hover .overlay {
    opacity: 1;
  }
  