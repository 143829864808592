.footer {
    background-color: #ffffff;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  .footer p {
    margin-top: 40px;
    font-family: Arial, sans-serif; /* Change the font family */
    font-size: 18px; /* Change the font size */
    color: #000000; /* Change the text color */

    font-weight: bold; /* Make the text bold */
  }
  
  
  